<template>
  <div class="animated fadeIn">
    <loading :active.sync="isLoading"></loading>
    <div class="container mb-5">
      <div class="card bg-white p-5 shadow mt-6">
        <div class="card-body">
          <div class="py-3 text-center">
            <h1>{{ $t('message.support') }}</h1>
            <p class="lead">
              {{ $t('message.support_intro') }}
            </p>
          </div>
          <form @submit.prevent="submit">
          <b-row>
            <b-col sm="12">
              <validation-field
                :label="$t('message.first_name')"
                :v="$v.form.name">
                <input class="form-control" id="name"
                       type="text"
                       v-model="form.name"
                       v-on:blur="$v.form.name.$touch()"
                />
              </validation-field>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <validation-field
                :label="$t('message.email_anonymous')"
                :v="$v.form.email">
                <input class="form-control" id="email"
                       type="text"
                       v-model="form.email"
                       v-on:blur="$v.form.email.$touch()"
                />
              </validation-field>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <validation-field
                :label="$t('message.telephone')"
                :v="$v.form.phone">
                <input class="form-control" id="phone"
                       type="text"
                       v-model="form.phone"
                       v-on:blur="$v.form.phone.$touch()"
                />
              </validation-field>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <validation-field
                :label="$t('message.subject')"
                :v="$v.form.subject">
                <input class="form-control" id="subject"
                       type="text"
                       v-model="form.subject"
                       v-on:blur="$v.form.subject.$touch()"
                />
              </validation-field>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <validation-field
                :label="$t('message.classification')"
                :v="$v.form.classification">
                <select class="form-control" v-model="form.classification"
                        v-on:blur="$v.form.classification.$touch()">
                  <option value="attivazioni_rinnovi">{{$t('message.attivazioni_rinnovi')}}</option>
                  <option value="diritto-recesso">{{$t('message.diritto_recesso')}}</option>
                  <option value="modifica-dati-amministrativi">{{$t('message.modifica_dati_amministrativi')}}</option>
                  <option value="pagamenti-fatturazioni">{{$t('message.pagamenti_fatturazioni')}}</option>
                  <option value="agevolazioni-iva">{{$t('message.agevolazioni_iva')}}</option>
                  <option value="altra-richiesta-commerciale">{{$t('message.altra_richiesta_commerciale')}}</option>
                  <option value="malfunzionamento">{{$t('message.malfunzionamento')}}</option>
                  <option value="assistenza-tecnica">{{$t('message.assistenza_tecnica')}}</option>
                </select>
              </validation-field>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12">
              <validation-field
                :label="$t('message.message')"
                :v="$v.form.message">
                  <textarea class="form-control" id="Message"
                            rows="15"
                            type="textarea"
                            v-model="form.message"
                            v-on:blur="$v.form.message.$touch()"
                  />
              </validation-field>
            </b-col>
          </b-row>
            <b-row>
              <b-col sm="12">
                {{$t('message.disclaimer')}}
              </b-col>
            </b-row>
          <b-row>
            <b-col sm="12" md="4">
              <b-input-group :class="{ 'error': $v.form.recaptchaVerified.$error }" class="mb-3">
                <vue-recaptcha
                  ref="recaptcha"
                  @verify="onVerify"
                  @expired="onExpired"
                  :sitekey="sitekey"
                  :language="this.$route.params.lang"
                  :loadRecaptchaScript="true"
                >
                </vue-recaptcha>
                <span class="error" v-if="$v.form.recaptchaVerified.$error">Obbligatorio</span>
              </b-input-group>
            </b-col>
          </b-row>
          <hr class="mb-4">
          <b-row>
            <b-col md="6" sm="12">
              <b-button
                :class="{invalid:$v.form.$invalid}"
                block
                class="bg-theme-secondary mb-2"
                type="submit">
                {{$t('message.button_send_text')}}
              </b-button>
            </b-col>
            <b-col md="6" sm="12">
              <b-button
                block type="button"
                v-on:click="reset()"
                variant="secondary">
                {{$t('message.button_reset_text')}}
              </b-button>
            </b-col>
          </b-row>
        </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ValidationField from "../../components/ui/ValidationField";
  import StarRating from "vue-star-rating";
  import Api from "../../components/common/Api";
  import ApiResolver from "../../components/common/ApiResolver";
  import {required, email, sameAs} from "vuelidate/lib/validators";
  import VueRecaptcha from 'vue-recaptcha';
  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: "Support",
    components: {
      ValidationField,
      VueRecaptcha,
      Loading
    },
    data() {
      return {
        isLoading: false,
        sitekey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
        form: {
          message: null,
          name: null,
          email: null,
          subject: null,
          phone: null,
          requestType: null,
          recaptchaVerified: false,
          classification: null
        }
      }
    },
    computed: {},
    methods: {
      onVerify: function (response) {
        this.form.recaptchaVerified = true
      },
      onExpired: function () {
        //console.log('Expired')
      },
      resetRecaptcha () {
        this.$refs.recaptcha.reset() // Direct call reset method
      },
      submit() {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) return;
        this.isLoading = true;
        Api
          .post(ApiResolver.get('PUBLIC_SUPPORT'), JSON.stringify(this.form))
          .then(response => {
            this.$store.dispatch('alerts/setAlert', {
              type: 'success',
              text: this.$t('message.ticket_opened')
            });
            this.isLoading = false;
            this.$router.push({name: 'categories'});
          })
          .catch(reason => {
            this.isLoading = false;
            this.$store.dispatch('alerts/setAlert', {
              type: 'danger',
              text: reason.title + ' : ' + reason.detail
            });
          });
      },
      reset() {
        this.form = {
          message: null,
          subject: null,
          name: null,
          phone: null,
          email: null,
        };
        this.$v.form.reset();
      }
    },
    mounted() {
    },
    validations: {
      form: {
        name: {
          required: required
        },
        phone : {

        },
        classification : {

        },
        email: {
          email,
          required: required
        },
        subject: {
          required: required
        },
        message: {
          required: required,
        },
        recaptchaVerified: {
          sameAs:  sameAs( () => true )
        }
      }

    },
  }
</script>

<style scoped>
  .hasError label {
    color: red;
  }

  .error {
    color: red
  }
</style>
